import { FinalA, Profile } from 'src/models/dto';
import { Api } from 'src/utils/api';
import { HeadersType } from 'src/utils/getHeaders';

export class ProfileApi {
  static async create(
    form: Profile.Form & {
      finalTemplate: FinalA.FINAL_TEMPLATE;
      enableSplitTests: boolean;
      quizTemplate: string;
    },
    headers: HeadersType
  ): Promise<string> {
    const res = await Api.post<Profile.FormResult>(
      '/v3.0/profile-summary',
      form,
      { headers }
    );

    return res.data.orderId;
  }
}

export default ProfileApi;
