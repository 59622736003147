import { memo } from 'react';
import ReactModal from 'react-modal';

import MonoIcon from 'src/components-2.0/MonoIcon';

import {
  ModalWrapper,
  ModalContent,
  ModalInner,
  ModalClose,
  ModalCloseButton,
} from './modal-styled';

ReactModal.setAppElement('#__next');

export type ModalProps = {
  isOpen: boolean;
  children: React.ReactNode;
  onRequestClose?: () => void;
  hideCloseBtn?: boolean;
  closeTimeoutMS?: number | undefined;
};

const MODAL_STYLES = {
  overlay: {
    backgroundColor: 'rgba(41,41,41,.9)',
    zIndex: 109,
  },
};

function Modal({
  isOpen,
  onRequestClose,
  hideCloseBtn = false,
  closeTimeoutMS = undefined,
  children,
  ...props
}: ModalProps): JSX.Element {
  return (
    <ModalWrapper
      style={MODAL_STYLES}
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      $isOnRequestClose={!!onRequestClose}
      closeTimeoutMS={closeTimeoutMS}
    >
      <ModalContent>
        <ModalInner {...props}>
          {!hideCloseBtn && (
            <ModalClose>
              <ModalCloseButton
                type="button"
                onClick={onRequestClose}
                title="Close"
              >
                <MonoIcon name="close" sizeRate={3.5} />
              </ModalCloseButton>
            </ModalClose>
          )}

          {children}
        </ModalInner>
      </ModalContent>
    </ModalWrapper>
  );
}

export default memo(Modal);
