import { MeasurementUnit } from 'src/constants/measurement-unit';
import { QuizForms } from 'src/containers/Quiz/models';
import { QuizSteps } from 'src/models/dto/quiz-steps-dto';

export const getLastMeasure = (
  quizForms: QuizForms,
  quizSteps: QuizSteps.QuizStep[]
): MeasurementUnit => {
  const stepWithMeasure = [...quizSteps]
    .reverse()
    .find((step) => !!quizForms?.[step.url]?.measure);

  return (stepWithMeasure &&
    quizForms?.[stepWithMeasure.url]?.measure) as MeasurementUnit;
};

export default getLastMeasure;
