export const quizFormsCookieKey = 'quiz_forms';
export const quizStepCookieKey = 'quiz_step';

export enum PriceTemplates {
  ONE = 'ONE',
  PRICING_MONO_2 = 'PRICING_MONO_2',
  PRICING_MONO_WITHOUT_UPSELL = 'PRICING_MONO_WITHOUT_UPSELL',
  THREE_SHORT_3 = 'THREE_SHORT_3',
  THREE_TRIAL_FULL_PERIOD_PRICE_NO_UPSELL = 'THREE_TRIAL_FULL_PERIOD_PRICE_NO_UPSELL',
  PRICING_WITH_PER_WEEK = 'PRICING_WITH_PER_WEEK',
  APPLE_GOOGLE_PAY_PRICING = 'APPLE_GOOGLE_PAY_PRICING',
  THREE_TRIAL_NO_UPSELL = 'THREE_TRIAL_NO_UPSELL',
  LEARN_DEFAULT = 'LEARN_DEFAULT',
  LEARN_THREE = 'LEARN_THREE',
  WORKOUT_UPSELL = 'WORKOUT_UPSELL',
}

export const templatesMap: { [key: string]: string } = {
  nuv1: PriceTemplates.THREE_TRIAL_FULL_PERIOD_PRICE_NO_UPSELL,
  nuv2: PriceTemplates.THREE_TRIAL_FULL_PERIOD_PRICE_NO_UPSELL,
  nuv3: PriceTemplates.THREE_TRIAL_FULL_PERIOD_PRICE_NO_UPSELL,
  nuv4: PriceTemplates.THREE_TRIAL_FULL_PERIOD_PRICE_NO_UPSELL,
  thrs3: PriceTemplates.THREE_SHORT_3,
  prm2: PriceTemplates.PRICING_MONO_2,
  prmwu: PriceTemplates.PRICING_MONO_WITHOUT_UPSELL,
  prwpw: PriceTemplates.PRICING_WITH_PER_WEEK,
  agppr: PriceTemplates.APPLE_GOOGLE_PAY_PRICING,
  ld: PriceTemplates.LEARN_DEFAULT,
  lt: PriceTemplates.LEARN_THREE,
  default: PriceTemplates.ONE,
  wu: PriceTemplates.WORKOUT_UPSELL,
};
