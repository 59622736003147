import axios, { AxiosInstance } from 'axios';

export const createAxiosInstance = (): AxiosInstance => {
  const instance = axios.create({
    baseURL: process.env.NEXT_PUBLIC_API_URL,
    headers: {
      Authorization: `Bearer ${process.env.NEXT_PUBLIC_AUTH_KEY}`,
    },
  });

  return instance;
};

export const Api = createAxiosInstance();
