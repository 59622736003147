import { QuizForms } from 'src/containers/Quiz/models';
import { getFlatFormValue } from 'src/containers/Quiz/utils/get-flat-form-value/get-flat-form-value';
import { getLastMeasure } from 'src/containers/Quiz/utils/get-last-measure/get-last-measure';
import { Profile, QuizSteps } from 'src/models/dto';

export const formValueToProfileForm = (
  quizForms: QuizForms,
  email: string,
  navigation: QuizSteps.DTO,
  utm: { [key: string]: string },
  analytics:
    | {
        fbPixel: {
          fbc?: string;
          fbp?: string;
        };
      }
    | {
        yahoo: {
          vmcid: string;
        };
      },
  pricePackageId?: number,
  priceTemplate?: string,
  wantReceiveMarketingEmails?: boolean
): Profile.Form => {
  const { quizSteps, id } = navigation;
  const choices = Object.values(quizForms).reduce((accum, { formValue }) => {
    const flatFormValue = getFlatFormValue(
      formValue as Record<string, unknown>
    );
    return [
      ...accum,
      ...Object.keys(flatFormValue).map(
        (key): Profile.ChoiceValue => ({
          choiceId: +key,
          choiceValue: flatFormValue[key],
        })
      ),
    ];
  }, [] as Profile.ChoiceValue[]);

  const measureUnit = getLastMeasure(quizForms, quizSteps);

  return {
    email,
    choices,
    measureUnit,
    quizStepGroupId: id,
    utm,
    pricePackageId,
    priceTemplate: priceTemplate as string,
    wantReceiveMarketingEmails: !!wantReceiveMarketingEmails,
    analytics,
  };
};

export default formValueToProfileForm;
