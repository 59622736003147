import styled, { css, CSSProp } from 'styled-components';

import { themeColor, themeFont } from 'src/theme/utils';

type ButtonWrapperProps = {
  $size: 'big' | 'medium' | 'small';
  $kind: 'primary' | 'secondary' | 'text';
};

export const ButtonWrapper = styled.button<ButtonWrapperProps>`
  align-items: center;
  border-radius: 8px;
  display: flex;
  font-family: ${themeFont('fontFamily')};
  font-weight: 500;
  justify-content: center;
  letter-spacing: 0.14px;
  border: 0;
  cursor: pointer;

  & > svg {
    height: 24px;
    line-height: 1;
    margin-right: 8px;
    overflow: hidden;
    width: 24px;
  }

  & > * ~ svg {
    margin-left: 8px;
    margin-right: 0;
  }

  ${({ $size }): CSSProp => {
    if ($size === 'big') {
      return css`
        font-size: 20px;
        line-height: 28px;
        padding: 14px 16px;
      `;
    }

    if ($size === 'medium') {
      return css`
        font-size: 20px;
        line-height: 28px;
        padding: 10px 16px;
      `;
    }

    if ($size === 'small') {
      return css`
        font-size: 20px;
        line-height: 28px;
        padding: 6px 16px;
      `;
    }

    return css``;
  }}

  ${({ $kind }): CSSProp => {
    if ($kind === 'primary') {
      return css`
        background-color: ${themeColor('primary50')};
        color: ${themeColor('black0')};

        &:hover,
        &.focus-visible {
          background-color: ${themeColor('primary40')};
        }

        &:active {
          background-color: ${themeColor('primary60')};
        }

        &:disabled {
          background-color: ${themeColor('primary10')};
          pointer-events: none;
          opacity: 1;
        }
      `;
    }

    if ($kind === 'secondary') {
      return css`
        background-color: ${themeColor('primary0')};
        color: ${themeColor('primary50')};

        &:hover,
        &.focus-visible {ну если прям новые урлы
          background-color: ${themeColor('primary0')};
          color: ${themeColor('primary30')};
        }

        &:active {
          background-color: ${themeColor('primary5')};
        }

        &:disabled {
          pointer-events: none;
          opacity: 0.3;
        }
      `;
    }

    if ($kind === 'text') {
      return css`
        background-color: transparent;
        color: ${themeColor('primary50')};

        &:active {
          background-color: ${themeColor('primary0')};
        }

        &:disabled {
          pointer-events: none;
          opacity: 0.3;
        }
      `;
    }

    return css``;
  }}
`;

// export const ButtonContent = styled.span``;

// export const ButtonIcon = styled.div`

// `;

export default ButtonWrapper;
