import { memo, useEffect, useState } from 'react';
import Image from 'next/image';
import useTranslation from 'next-translate/useTranslation';

import { amplitudeLogEvent } from 'src/utils/amplitude';
import { getCookie, setCookie } from 'src/utils/cookie';
import { sendGTMEvent } from 'src/utils/gtm';

import {
  EmailConsentModalInner,
  EmailConsentModalSelectorImageWrapper,
  EmailConsentModalSelectorImage,
  EmailConsentModalSelectorWrapper,
  EmailConsentModalSelectorTitle,
  EmailConsentModalSelectorCaption,
  EmailConsentModalSelectorAgreeButton,
  EmailConsentModalSelectorDeclineButton,
} from './email-consent-modal-selector-styled';

type EmailConsentModalSelectorProps = {
  onSelect: () => void;
};

function EmailConsentModalSelector({
  onSelect,
}: EmailConsentModalSelectorProps): JSX.Element {
  const { t } = useTranslation('quiz');
  const [isBtnsDisabled, setIsBtnDisabled] = useState(false);

  const handleAccept = (): void => {
    setCookie('wantReceiveMarketingEmails', '1');
    setIsBtnDisabled(true);
    onSelect();
  };

  const handleDecline = (): void => {
    setCookie('wantReceiveMarketingEmails', '0');
    setIsBtnDisabled(true);
    onSelect();
  };

  useEffect(() => {
    amplitudeLogEvent('email-consent-modal-shown');

    sendGTMEvent({
      event: `email-consent-modal-shown`,
      ptm: getCookie('ptm'),
      ftm: getCookie('ftm'),
      pricePackageId: getCookie('pricePackageId'),
      expGrp: getCookie('expGrp'),
      expName: getCookie('expName'),
    });
  }, []);

  return (
    <EmailConsentModalInner isOpen hideCloseBtn>
      <EmailConsentModalSelectorWrapper>
        <EmailConsentModalSelectorImageWrapper>
          <EmailConsentModalSelectorImage>
            <Image
              src={`${process.env.ASSETS_PUBLIC_PATH}images/quiz/stepEmail/email-consent.svg`}
              width="504"
              height="160"
              alt=""
              layout="fixed"
            />
          </EmailConsentModalSelectorImage>
        </EmailConsentModalSelectorImageWrapper>

        <EmailConsentModalSelectorTitle>
          {t('emailConsentModalTitle')}
        </EmailConsentModalSelectorTitle>

        <EmailConsentModalSelectorCaption>
          {t('emailConsentModalCaption')}
        </EmailConsentModalSelectorCaption>

        <EmailConsentModalSelectorAgreeButton
          kind="primary"
          size="big"
          type="button"
          onClick={handleAccept}
          title={t('emailConsentModalAgreeBtnText')}
          disabled={isBtnsDisabled}
        >
          {t('emailConsentModalAgreeBtnText')}
        </EmailConsentModalSelectorAgreeButton>

        <EmailConsentModalSelectorDeclineButton
          type="button"
          onClick={handleDecline}
          as="button"
          title={t('emailConsentModalDeclineBtnText')}
          disabled={isBtnsDisabled}
        >
          {t('emailConsentModalDeclineBtnText')}
        </EmailConsentModalSelectorDeclineButton>
      </EmailConsentModalSelectorWrapper>
    </EmailConsentModalInner>
  );
}

export default memo(EmailConsentModalSelector);
