import { MeasurementUnit } from 'src/constants/measurement-unit';

export namespace Quiz {
  export enum StepTemplate {
    BELLY = 'BELLY',
    BODY_TYPE = 'BODY_TYPE',
    CHRONOTYPE = 'CHRONOTYPE',
    GENDER = 'GENDER',
    GOAL = 'GOAL',
    FASTING_PLAN = 'FASTING_PLAN',
    HEALTHY_DIET = 'HEALTHY_DIET',
    LOSE_WEIGHT = 'LOSE_WEIGHT',
    GOAL_NI = 'GOAL_NI',
    BEST_HEALTHY_MEAL_PLAN_FOR_YOU = 'BEST_HEALTHY_MEAL_PLAN_FOR_YOU',
    GET_RIGHT_NUTRITION_PLAN_FOR_YOU = 'GET_RIGHT_NUTRITION_PLAN_FOR_YOU',
    BEST_HEALTHY_DIET_FOR_YOU = 'BEST_HEALTHY_DIET_FOR_YOU',
    LETS_CREATE_YOU_BODY_PROFILE = 'LETS_CREATE_YOU_BODY_PROFILE',
    LETS_CREATE_YOU_BODY_PROFILE_WHITE = 'LETS_CREATE_YOU_BODY_PROFILE_WHITE',
    LETS_CREATE_YOU_BODY_PROFILE_YELLOW = 'LETS_CREATE_YOU_BODY_PROFILE_YELLOW',
    INTERMITTENT_FASTING = 'INTERMITTENT_FASTING',
    EFFECTIVE_DIET_FOR_WEIGHT_LOSS = 'EFFECTIVE_DIET_FOR_WEIGHT_LOSS',
    GET_RIGHT_NUTRITION_PLAN_FOR_YOU_YELLOW = 'GET_RIGHT_NUTRITION_PLAN_FOR_YOU_YELLOW',
    HEALTHY_PLANS_TEMPLATE = 'HEALTHY_PLANS_TEMPLATE',
    EAT_HAPPY_HEALTHY_TEMPLATE = 'EAT_HAPPY_HEALTHY_TEMPLATE',
    GET_SHAPE_CLUB_TEMPLATE = 'GET_SHAPE_CLUB_TEMPLATE',
    HEALTHY_CARE_ONLINE_COM_TEMPLATE = 'HEALTHY_CARE_ONLINE_COM_TEMPLATE',
    EATING_PRO_CLUB_TEMPLATE = 'EATING_PRO_CLUB_TEMPLATE',
    PERSONALIZED_MEALS_CLUB_TEMPLATE = 'PERSONALIZED_MEALS_CLUB_TEMPLATE',
    AMO_FIT_CLUB_TEMPLATE = 'AMO_FIT_CLUB_TEMPLATE',
    DIET_COACHES_ME_TEMPLATE = 'DIET_COACHES_ME_TEMPLATE',
    FASTING_PRO_ME_TEMPLATE = 'FASTING_PRO_ME_TEMPLATE',
    FEEL_GOOD_INC_TEMPLATE = 'FEEL_GOOD_INC_TEMPLATE',
    MEAL_CHALLENGE_TEMPLATE = 'MEAL_CHALLENGE_TEMPLATE',
    CONGRATS_TEMPLATE = 'CONGRATS_TEMPLATE',
    HEALTHY_MEALS_ME_TEMPLATE = 'HEALTHY_MEALS_ME_TEMPLATE',
    BODY_MAKER_LIVE_TEMPLATE = 'BODY_MAKER_LIVE_TEMPLATE',
    BODY_IMPROVER_INFO_TEMPLATE = 'BODY_IMPROVER_INFO_TEMPLATE',
    BETTER_PLAN_CLUB_TEMPLATE = 'BETTER_PLAN_CLUB_TEMPLATE',
    BEST_WEIGHT_CLUB_TEMPLATE = 'BEST_WEIGHT_CLUB_TEMPLATE',
    IMPROVEME_CLUB_TEMPLATE = 'IMPROVEME_CLUB_TEMPLATE',
    GET_HEALTHY_SPACE_TEMPLATE = 'GET_HEALTHY_SPACE_TEMPLATE',
    WEIGHT_DECREASE_COM_TEMPLATE = 'WEIGHT_DECREASE_COM_TEMPLATE',
    GO_HUNGRY_INFO_TEMPLATE = 'GO_HUNGRY_INFO_TEMPLATE',
    EAT_NOTHING_INFO_TEMPLATE = 'EAT_NOTHING_INFO_TEMPLATE',
    BEST_SNACK_COM_TEMPLATE = 'BEST_SNACK_COM_TEMPLATE',
    HEALTHY_BITES_CLUB_TEMPLATE = 'HEALTHY_BITES_CLUB_TEMPLATE',
    RIGHT_SHAPE_INFO_TEMPLATE = 'RIGHT_SHAPE_INFO_TEMPLATE',
    PERFECT_BODY_INFO_TEMPLATE = 'PERFECT_BODY_INFO_TEMPLATE',
    DIET_MAKER_CLUB_TEMPLATE = 'DIET_MAKER_CLUB_TEMPLATE',
    ENERGY_MONSTER_LIVE_TEMPLATE = 'ENERGY_MONSTER_LIVE_TEMPLATE',
    GAIN_WEIGHT_FUN_TEMPLATE = 'GAIN_WEIGHT_FUN_TEMPLATE',
    DIET_HACKER_INFO_TEMPLATE = 'DIET_HACKER_INFO_TEMPLATE',
    BODY_GUARD_ROCKS_TEMPLATE = 'BODY_GUARD_ROCKS_TEMPLATE',
    FIT_KEEPER_INFO_TEMPLATE = 'FIT_KEEPER_INFO_TEMPLATE',
    TOP_PRIORITY_NOW = 'TOP_PRIORITY_NOW',
    HEALTHY_NUTRITION_MADE_SIMPLE = 'HEALTHY_NUTRITION_MADE_SIMPLE',
    WEIGHT_LOSS_MADE_EASY_WITH_DISH_PICTURE = 'WEIGHT_LOSS_MADE_EASY_WITH_DISH_PICTURE',
    WEIGHT_LOSS_MADE_EASY_WITH_MAN_WOMAN_PICTURE = 'WEIGHT_LOSS_MADE_EASY_WITH_MAN_WOMAN_PICTURE',
  }

  export enum QuizType {
    BELLY = 'BELLY',
    BODY_TYPE = 'BODY_TYPE',
    CHRONOTYPE = 'CHRONOTYPE',
    GENDER = 'GENDER',
    GOAL = 'GOAL',
    TYPICAL_DAY = 'TYPICAL_DAY',
    FITNESS = 'FITNESS',
    WATER = 'WATER',
    SLEEP = 'SLEEP',
    MEASURES = 'MEASURES',
    VEGGIES = 'VEGGIES',
    PRODUCTS = 'PRODUCTS',
    MEAT = 'MEAT',
    FINAL = 'FINAL',
    RESTRICTIONS = 'RESTRICTIONS',
    TIME_PREPARATION = 'TIME_PREPARATION',
    DESIRED_WEIGHT = 'DESIRED_WEIGHT',
  }

  export enum ChoiceType {
    AGES = 'AGES',
    HEIGHT_METERS_FOOTS = 'HEIGHT_METERS_FOOTS',
    WEIGHT = 'WEIGHT',
    TARGET_WEIGHT = 'TARGET_WEIGHT',
    WOMAN = 'WOMAN',
    MAN = 'MAN',
    HEIGHT_INCH = 'HEIGHT_INCH',
  }

  export enum InputType {
    VALUE = 'VALUE',
    INGREDIENT = 'INGREDIENT',
    RANGE = 'RANGE',
    MARK = 'MARK',
  }

  export interface Choice {
    id: number;
    analyticId: string;
    title: string;
    subTitle?: string;
    type: ChoiceType;
    inputType: InputType;
    iconUrl: string;
    measurementUnit: MeasurementUnit;
    isDenySelectAnotherChoices: boolean;
    value: string;
    rangeStart: number;
    rangeEnd: number;
    position: number;
    restrictedByChoiceIds: number[];
    hiddenByChoiceIds: number[];
    placeHolder?: string;
  }

  export interface StaticPage {
    position: number;
    body: string;
    analyticId: string;
  }

  export interface Nav {
    type: 'select' | 'input';
    id: number;
    measure: MeasurementUnit;
    hiddenIds: number[];
  }

  export interface SelectReset {
    id: number;
    title: string;
    analyticId: string;
  }

  export interface SelectOption {
    id: number;
    title: string;
    description?: string;
    icon?: string;
    iconSize?: 'sm' | 'md';
    disableIds: number[];
    value?: string;
    analyticId: string;
  }

  export interface Select {
    id: number;
    isMultiple: boolean;
    isInline: boolean;
    reset: SelectReset;
    options: SelectOption[];
  }

  export interface InputValidation {
    min?: number;
    max?: number;
  }

  export interface Input {
    id: number;
    title: string;
    placeHolder?: string;
    analyticId: string;
    validation: InputValidation;
  }

  export interface InputGroup {
    id: number;
    controls: Input[];
  }

  export interface DTO {
    title: string;
    subtitle: string;
    type: QuizType;
    template: StepTemplate;
    iconUrl: string;
    iconInactiveUrl: string;
    url: string;
    description: string;
    isAutoSubmit: boolean;
    isWithMeasurementUnits: boolean;
    staticPage: StaticPage;
    nav: Nav[];
    selects: Record<string, Select>;
    inputs: Record<string, InputGroup>;
  }
}
