import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { memo, useEffect, useReducer, useRef, useState } from 'react';
import * as Sentry from '@sentry/nextjs';

import { stringify } from 'qs';

import { FinalA, Quiz as Q, QuizSteps } from 'src/models/dto';
import { ProfileApi } from 'src/services';

import {
  amplitudeLogEvent,
  amplitudeSetUserProperties,
} from 'src/utils/amplitude';
import { sendGTMEvent } from 'src/utils/gtm';
import { partialReducer } from 'src/utils/partialReducer';
import { getCookie, setCookie } from 'src/utils/cookie';

import { StepLearnPageProps } from 'src/containers/Quiz/components/StepLearnPage/StepLearnPage';

import EmailConsentModalSelector from '../../components-2.0/EmailConsentModalSelector';

import {
  quizFormsCookieKey,
  quizStepCookieKey,
  templatesMap,
} from './constants';

import { StepForm, StepProps } from './models';
import {
  formValueToProfileForm,
  QuizFormsProvider,
  sendQuizFormEvents,
} from './utils';
import { QuizProps } from './getProps';
import { ProgressProps } from './components';

const StepGoal: React.ComponentType<StepProps & { groupUrl: string }> = dynamic(
  () => import('src/containers/Quiz/components/StepGoal/StepGoal') as any
);
const DataProcessing: React.ComponentType<{ completed: () => void }> = dynamic(
  () =>
    import(
      'src/containers/Quiz/components/DataProcessing/DataProcessing'
    ) as any
);
const EmailForm: React.ComponentType<{
  onSubmit: (email: string) => Promise<void>;
}> = dynamic(
  () => import('src/containers/Quiz/components/EmailForm/EmailForm') as any
);
const StaticPage: React.ComponentType<{
  analyticId: string;
  body: any;
  onClose: () => void;
}> = dynamic(
  () => import('src/containers/Quiz/components/StaticPage/StaticPage') as any
);
const StepLearnPage: React.ComponentType<StepLearnPageProps> = dynamic(
  () =>
    import('src/containers/Quiz/components/StepLearnPage/StepLearnPage') as any
);
const Step: React.ComponentType<StepProps> = dynamic(
  () => import('src/containers/Quiz/components/Step/Step') as any
);
const StepBodytype: React.ComponentType<StepProps> = dynamic(
  () =>
    import('src/containers/Quiz/components/StepBodytype/StepBodytype') as any
);
const Progress: React.ComponentType<ProgressProps> = dynamic(
  () => import('src/containers/Quiz/components/Progress/Progress') as any
);
const StepChronotype: React.ComponentType<StepProps> = dynamic(
  () =>
    import(
      'src/containers/Quiz/components/StepChronotype/StepChronotype'
    ) as any
);
const StepBellytype: React.ComponentType<StepProps> = dynamic(
  () =>
    import('src/containers/Quiz/components/StepBellytype/StepBellytype') as any
);
const StepGender: React.ComponentType<StepProps> = dynamic(
  () => import('src/containers/Quiz/components/StepGender/StepGender') as any
);
const StepWristGirth: React.ComponentType<StepProps> = dynamic(
  () =>
    import(
      'src/containers/Quiz/components/StepWristGirth/StepWristGirth'
    ) as any
);
const StepHealthyDiet: React.ComponentType<StepProps> = dynamic(
  () =>
    import(
      'src/containers/Quiz/components/StepHealthyDiet/StepHealthyDiet'
    ) as any
);
const StepLoseWeight: React.ComponentType<StepProps> = dynamic(
  () =>
    import(
      'src/containers/Quiz/components/StepLoseWeight/StepLoseWeight'
    ) as any
);
const StepGoalNi: React.ComponentType<
  StepProps & {
    groupUrl: string;
  }
> = dynamic(
  () => import('src/containers/Quiz/components/StepGoalNi/StepGoalNi') as any
);
const StepMealsPlan: React.ComponentType<StepProps> = dynamic(
  () =>
    import('src/containers/Quiz/components/StepMealsPlan/StepMealsPlan') as any
);
const StepBodyWellness: React.ComponentType<StepProps> = dynamic(
  () =>
    import(
      'src/containers/Quiz/components/StepBodyWellness/StepBodyWellness'
    ) as any
);
const StepHealthWellness: React.ComponentType<StepProps> = dynamic(
  () =>
    import(
      'src/containers/Quiz/components/StepHealthWellness/StepHealthWellness'
    ) as any
);
const StepHealthyLifetime: React.ComponentType<StepProps> = dynamic(
  () =>
    import(
      'src/containers/Quiz/components/StepHealthyLifetime/StepHealthyLifetime'
    ) as any
);
const StepYourBodyWell: React.ComponentType<StepProps> = dynamic(
  () =>
    import(
      'src/containers/Quiz/components/StepYourBodyWell/StepYourBodyWell'
    ) as any
);
const StepFastingTrainer: React.ComponentType<StepProps> = dynamic(
  () =>
    import(
      'src/containers/Quiz/components/StepFastingTrainer/StepFastingTrainer'
    ) as any
);
const StepDietPlanners: React.ComponentType<StepProps> = dynamic(
  () =>
    import(
      'src/containers/Quiz/components/StepDietPlanners/StepDietPlanners'
    ) as any
);
const StepBodyCoach: React.ComponentType<StepProps> = dynamic(
  () =>
    import('src/containers/Quiz/components/StepBodyCoach/StepBodyCoach') as any
);
const StepWeightCutting: React.ComponentType<StepProps> = dynamic(
  () =>
    import(
      'src/containers/Quiz/components/StepWeightCutting/StepWeightCutting'
    ) as any
);
const StepHealthyPlans: React.ComponentType<StepProps> = dynamic(
  () =>
    import(
      'src/containers/Quiz/components/StepHealthyPlans/StepHealthyPlans'
    ) as any
);
const EatHappyHealthy: React.ComponentType<StepProps> = dynamic(
  () =>
    import(
      'src/containers/Quiz/components/EatHappyHealthy/EatHappyHealthy'
    ) as any
);
const GetShape: React.ComponentType<StepProps> = dynamic(
  () => import('src/containers/Quiz/components/GetShape/GetShape') as any
);
const HealthyCareOnline: React.ComponentType<StepProps> = dynamic(
  () =>
    import(
      'src/containers/Quiz/components/HealthyCareOnline/HealthyCareOnline'
    ) as any
);
const EatingPro: React.ComponentType<StepProps> = dynamic(
  () => import('src/containers/Quiz/components/EatingPro/EatingPro') as any
);
const PersonalizedMeals: React.ComponentType<StepProps> = dynamic(
  () =>
    import(
      'src/containers/Quiz/components/PersonalizedMeals/PersonalizedMeals'
    ) as any
);
const Amofit: React.ComponentType<StepProps> = dynamic(
  () => import('src/containers/Quiz/components/Amofit/Amofit') as any
);
const DietCoaches: React.ComponentType<StepProps> = dynamic(
  () => import('src/containers/Quiz/components/DietCoaches/DietCoaches') as any
);
const FastingPro: React.ComponentType<StepProps> = dynamic(
  () => import('src/containers/Quiz/components/FastingPro/FastingPro') as any
);
const FeelGoodInc: React.ComponentType<StepProps> = dynamic(
  () => import('src/containers/Quiz/components/FeelGoodInc/FeelGoodInc') as any
);
const MealChallenge: React.ComponentType<StepProps> = dynamic(
  () =>
    import('src/containers/Quiz/components/MealChallenge/MealChallenge') as any
);
const Congrats: React.ComponentType<StepProps> = dynamic(
  () => import('src/containers/Quiz/components/Congrats/Congrats') as any
);
const HealthyMeals: React.ComponentType<StepProps> = dynamic(
  () => import('src/containers/Quiz/components/HealthyMeals')
);
const Bodymaker: React.ComponentType<StepProps> = dynamic(
  () => import('src/containers/Quiz/components/Bodymaker')
);
const BestWeight: React.ComponentType<
  StepProps & {
    userCountryInfo: { countryCode: string };
  }
> = dynamic(() => import('src/containers/Quiz/components/BestWeight'));
const BetterPlan: React.ComponentType<StepProps> = dynamic(
  () => import('src/containers/Quiz/components/BetterPlan')
);
const Bodyimprover: React.ComponentType<StepProps> = dynamic(
  () => import('src/containers/Quiz/components/Bodyimprover')
);
const ImproveMe: React.ComponentType<StepProps> = dynamic(
  () => import('src/containers/Quiz/components/ImproveMe')
);
const GetHealthy: React.ComponentType<StepProps> = dynamic(
  () => import('src/containers/Quiz/components/GetHealthy')
);
const WeightDecrease: React.ComponentType<StepProps> = dynamic(
  () => import('src/containers/Quiz/components/WeightDecrease')
);
const GoHungry: React.ComponentType<StepProps> = dynamic(
  () => import('src/containers/Quiz/components/GoHungry')
);
const EatNothing: React.ComponentType<StepProps> = dynamic(
  () => import('src/containers/Quiz/components/EatNothing')
);
const BestSnack: React.ComponentType<StepProps> = dynamic(
  () => import('src/containers/Quiz/components/BestSnack')
);
const HealthyBites: React.ComponentType<StepProps> = dynamic(
  () => import('src/containers/Quiz/components/HealthyBites')
);
const RightShape: React.ComponentType<StepProps> = dynamic(
  () => import('src/containers/Quiz/components/RightShape')
);
const PerfectBody: React.ComponentType<StepProps> = dynamic(
  () => import('src/containers/Quiz/components/PerfectBody')
);
const DietMaker: React.ComponentType<StepProps> = dynamic(
  () => import('src/containers/Quiz/components/DietMaker')
);
const EnergyMonster: React.ComponentType<StepProps> = dynamic(
  () => import('src/containers/Quiz/components/EnergyMonster')
);
const GainWeight: React.ComponentType<StepProps> = dynamic(
  () => import('src/containers/Quiz/components/GainWeight')
);
const DietHacker: React.ComponentType<StepProps> = dynamic(
  () => import('src/containers/Quiz/components/DietHacker')
);
const BodyGuard: React.ComponentType<StepProps> = dynamic(
  () => import('src/containers/Quiz/components/BodyGuard')
);
const Fitkeeper: React.ComponentType<StepProps> = dynamic(
  () => import('src/containers/Quiz/components/Fitkeeper')
);

const StartOne: React.ComponentType<StepProps> = dynamic(
  () => import('src/containers/Quiz/components/StartOne') as any
);
const StartTwo: React.ComponentType<StepProps> = dynamic(
  () => import('src/containers/Quiz/components/StartTwo') as any
);
const StartThree: React.ComponentType<StepProps> = dynamic(
  () => import('src/containers/Quiz/components/StartThree') as any
);
const StartFour: React.ComponentType<StepProps> = dynamic(
  () => import('src/containers/Quiz/components/StartFour') as any
);

interface QuizState {
  showEmailForm?: boolean;
  showProcessing?: boolean;
  showStaticPage?: boolean;
  submittedFormValue?: StepForm;
}

const quizReducer = partialReducer<QuizState>();

function Quiz({
  quizData,
  navigation,
  stepUrl,
  quizForms,
  headers,
  groupUrl,
  firstStepUrl,
  userCountryInfo,
}: QuizProps & { userCountryInfo: { showModalWindow: boolean } }): JSX.Element {
  const router = useRouter();

  const [state, updateState] = useReducer(quizReducer, {});

  const userEmailRef = useRef('');
  const isSubmittingRef = useRef(false);

  const [showEmailConsentModal, toggleEmailConsentModal] = useState(false);

  useEffect(() => {
    setCookie('quizFirstStepUrl', firstStepUrl);
    setCookie('groupUrl', groupUrl);

    setCookie(quizStepCookieKey, stepUrl, {
      expires: 1 / 24,
    });
    setCookie(quizFormsCookieKey, JSON.stringify(quizForms), {
      expires: 1 / 24,
    });
  }, []);

  useEffect((): void => {
    amplitudeLogEvent(stepUrl);
    sendGTMEvent({
      event: 'stepShown',
      stepName: stepUrl,
      ptm: templatesMap[getCookie('ptm')] || templatesMap.default,
      pricePackageId: getCookie('pricePackageId'),
      quiz_step_group: groupUrl,
      language: router.locale,
      source: getCookie('utm_source'),
      expGrp: getCookie('expGrp'),
      expName: getCookie('expName'),
      quiz_step: stepUrl,
    });
  }, [stepUrl, router.locale]);

  useEffect(() => {
    updateState({
      showStaticPage: false,
      submittedFormValue: null as unknown as StepForm,
    });
  }, [router.query.step]);

  const handleStepChange = async (step: QuizSteps.QuizStep): Promise<void> => {
    const queryString = stringify(groupUrl ? { group: groupUrl } : {});

    router.push(`/${step.url}?${queryString}`);
  };

  const handleStepSubmit = async (
    form: StepForm,
    skipStaticPage = false
  ): Promise<void> => {
    if (isSubmittingRef.current) {
      return;
    }

    isSubmittingRef.current = true;

    if (!skipStaticPage || router.query.step !== 'step-learn-new') {
      sendQuizFormEvents(form.formValue as Record<string, unknown>, quizData);
    }

    if (quizData?.staticPage && !skipStaticPage) {
      isSubmittingRef.current = false;

      updateState({
        showStaticPage: true,
        submittedFormValue: form,
      });
      return;
    }
    const currentStepIndex = navigation.quizSteps.findIndex(
      ({ url }): boolean => url === stepUrl
    );
    const nextStep = navigation.quizSteps[currentStepIndex + 1];

    setCookie(
      quizFormsCookieKey,
      JSON.stringify({ ...quizForms, [stepUrl]: form }),
      {
        expires: 1 / 24,
      }
    );

    if (nextStep) {
      setCookie(quizStepCookieKey, nextStep.url, {
        expires: 1 / 24,
      });
      await handleStepChange(nextStep);
      sendGTMEvent({
        event: 'stepChosen',
        ptm: templatesMap[getCookie('ptm')] || templatesMap.default,
        pricePackageId: getCookie('pricePackageId'),
        quiz_step_group: groupUrl,
        expGrp: getCookie('expGrp'),
        expName: getCookie('expName'),
        stepName: stepUrl,
        ...form,
      });
    } else {
      updateState({ showProcessing: true });
    }

    isSubmittingRef.current = false;
  };

  const createUserProfile = async (): Promise<void> => {
    const wantReceiveMarketingEmails =
      getCookie('wantReceiveMarketingEmails') === '1';

    const fbc = getCookie('_fbc');
    const customFbc = getCookie('custom_fbc');
    const fbp = getCookie('_fbp');
    const fbclid = getCookie('amplitude_fbclid');
    const vmcid = getCookie('vmcid');

    const amplitudeFBUserProperties = {
      ...(fbc ? { fbc } : {}),
      ...(fbp ? { fbp } : {}),
      ...(fbclid ? { fbclid } : {}),
    };

    amplitudeSetUserProperties(amplitudeFBUserProperties);

    const form = formValueToProfileForm(
      JSON.parse(getCookie(quizFormsCookieKey) || '{}'),
      userEmailRef.current,
      navigation,
      {
        utm_source: getCookie('utm_source'),
        utm_campaign: getCookie('utm_campaign'),
        utm_term: getCookie('utm_term'),
        utm_medium: getCookie('utm_medium'),
      },
      vmcid && !fbc
        ? {
            yahoo: {
              vmcid,
            },
          }
        : {
            fbPixel: {
              fbc: (fbc || customFbc || null) as string,
              fbp: (fbp || null) as string,
            },
          },
      getCookie('pricePackageId'),
      templatesMap[getCookie('ptm')] || templatesMap.default,
      wantReceiveMarketingEmails
    );

    try {
      const orderId = await ProfileApi.create(
        {
          ...form,
          // @ts-ignore
          finalTemplate: (FinalA.FINAL_TEMPLATE_URL_NAMES[getCookie('ftm')] ||
            FinalA.FINAL_TEMPLATE.DEFAULT) as FinalA.FINAL_TEMPLATE,
          enableSplitTests: getCookie('st') !== '0',
          quizTemplate: 'OLD_QUIZ_API_VERSION',
        },
        headers
      );
      sendGTMEvent({
        event: 'setUserProperties',
        email: userEmailRef.current,
      });
      await router.push(`/final/${orderId}`);
    } catch (error) {
      Sentry.setContext('form_data', { data: JSON.stringify(form) });
      Sentry.setContext('quiz_values', {
        appValues: getCookie(quizFormsCookieKey),
      });
      throw error;
    }
  };

  const handleEmailConsentSelectorChange = (): void => {
    const isWantReceiveEmails = getCookie('wantReceiveMarketingEmails');

    amplitudeLogEvent(
      `consent-modal-${isWantReceiveEmails === '1' ? 'yes' : 'no'}`
    );
    sendGTMEvent({
      event: `consent-modal-${isWantReceiveEmails === '1' ? 'yes' : 'no'}`,
      ptm: getCookie('ptm'),
      ftm: getCookie('ftm'),
      pricePackageId: getCookie('pricePackageId'),
      quiz_step_group: groupUrl,
      expGrp: getCookie('expGrp'),
      expName: getCookie('expName'),
      stepName: stepUrl,
    });

    createUserProfile();
  };

  const handleEmailSubmit = async (email: string): Promise<void> => {
    userEmailRef.current = email;

    createUserProfile();
  };

  const handleStaticPageClose = (): void => {
    const form = state.submittedFormValue || {
      formValue: {},
    };
    handleStepSubmit(form, true);
  };

  const stepProps =
    quizData && navigation
      ? ({
          data: quizData,
          onSubmit: handleStepSubmit,
          choicesIdsMap: navigation.phrasesMap,
          quizSteps: navigation.quizSteps,
          defaultMeasure: navigation.measureUnit,
          quizForms,
          defaultValues: quizData?.url
            ? quizForms?.[quizData.url]?.formValue || {}
            : {},
          showEmailCheckbox: true,
          userCountryInfo,
        } as unknown as StepProps)
      : ({} as StepProps);

  const getContent = (): JSX.Element => {
    if (!(quizData && navigation && stepUrl)) {
      return <></>;
    }

    if (state.showStaticPage && quizData?.staticPage) {
      return (
        <StaticPage
          analyticId={quizData?.staticPage?.analyticId}
          body={quizData?.staticPage?.body}
          onClose={handleStaticPageClose}
        />
      );
    }

    if (state.showProcessing) {
      return (
        <DataProcessing
          completed={(): void =>
            updateState({ showProcessing: false, showEmailForm: true })
          }
        />
      );
    }
    if (state.showEmailForm) {
      return <EmailForm onSubmit={handleEmailSubmit} />;
    }

    if (router.query.step === 'step-learn-new') {
      return (
        <StepLearnPage
          onNext={handleStaticPageClose}
          stepUrl={stepUrl}
          items={navigation.quizSteps}
        />
      );
    }

    switch (quizData?.template) {
      case Q.StepTemplate.GENDER:
        return <StepGender {...stepProps} />;
      case Q.StepTemplate.BELLY:
        return <StepBellytype {...stepProps} />;
      case Q.StepTemplate.BODY_TYPE:
        return <StepBodytype {...stepProps} />;
      case Q.StepTemplate.CHRONOTYPE:
        return <StepChronotype {...stepProps} />;
      case Q.StepTemplate.GOAL:
        return <StepGoal {...stepProps} groupUrl={groupUrl} />;
      case Q.StepTemplate.GOAL_NI:
        return <StepGoalNi {...stepProps} groupUrl={groupUrl} />;
      case Q.StepTemplate.FASTING_PLAN:
        return <StepWristGirth {...stepProps} />;
      case Q.StepTemplate.HEALTHY_DIET:
        return <StepHealthyDiet {...stepProps} />;
      case Q.StepTemplate.LOSE_WEIGHT:
        return <StepLoseWeight {...stepProps} />;
      case Q.StepTemplate.BEST_HEALTHY_MEAL_PLAN_FOR_YOU:
        return <StepMealsPlan {...stepProps} />;
      case Q.StepTemplate.GET_RIGHT_NUTRITION_PLAN_FOR_YOU:
        return <StepBodyWellness {...stepProps} />;
      case Q.StepTemplate.BEST_HEALTHY_DIET_FOR_YOU:
        return <StepHealthWellness {...stepProps} />;
      case Q.StepTemplate.LETS_CREATE_YOU_BODY_PROFILE:
        return <StepYourBodyWell {...stepProps} />;
      case Q.StepTemplate.LETS_CREATE_YOU_BODY_PROFILE_WHITE:
        return <StepHealthyLifetime {...stepProps} />;
      case Q.StepTemplate.INTERMITTENT_FASTING:
        return <StepFastingTrainer {...stepProps} />;
      case Q.StepTemplate.EFFECTIVE_DIET_FOR_WEIGHT_LOSS:
        return <StepDietPlanners {...stepProps} />;
      case Q.StepTemplate.LETS_CREATE_YOU_BODY_PROFILE_YELLOW:
        return <StepBodyCoach {...stepProps} />;
      case Q.StepTemplate.GET_RIGHT_NUTRITION_PLAN_FOR_YOU_YELLOW:
        return <StepWeightCutting {...stepProps} />;
      case Q.StepTemplate.HEALTHY_PLANS_TEMPLATE:
        return <StepHealthyPlans {...stepProps} />;
      case Q.StepTemplate.EAT_HAPPY_HEALTHY_TEMPLATE:
        return <EatHappyHealthy {...stepProps} />;
      case Q.StepTemplate.GET_SHAPE_CLUB_TEMPLATE:
        return <GetShape {...stepProps} />;
      case Q.StepTemplate.HEALTHY_CARE_ONLINE_COM_TEMPLATE:
        return <HealthyCareOnline {...stepProps} />;
      case Q.StepTemplate.EATING_PRO_CLUB_TEMPLATE:
        return <EatingPro {...stepProps} />;
      case Q.StepTemplate.PERSONALIZED_MEALS_CLUB_TEMPLATE:
        return <PersonalizedMeals {...stepProps} />;
      case Q.StepTemplate.AMO_FIT_CLUB_TEMPLATE:
        return <Amofit {...stepProps} />;
      case Q.StepTemplate.DIET_COACHES_ME_TEMPLATE:
        if (
          process.env.CONFIG === 'harnafit' ||
          process.env.CONFIG === 'harnatech'
        ) {
          return (
            <QuizFormsProvider quizForms={quizForms} stepUrl={stepUrl}>
              <Progress
                onPrev={handleStepChange}
                selectedItemUrl={stepUrl}
                items={navigation.quizSteps}
              />
              <Step {...stepProps} key={router.query.step as string} />
            </QuizFormsProvider>
          );
        }

        return <DietCoaches {...stepProps} />;
      case Q.StepTemplate.FASTING_PRO_ME_TEMPLATE:
        return <FastingPro {...stepProps} />;
      case Q.StepTemplate.FEEL_GOOD_INC_TEMPLATE:
        return <FeelGoodInc {...stepProps} />;
      case Q.StepTemplate.MEAL_CHALLENGE_TEMPLATE:
        return <MealChallenge {...stepProps} />;
      case Q.StepTemplate.CONGRATS_TEMPLATE:
        return <Congrats {...stepProps} />;
      case Q.StepTemplate.HEALTHY_MEALS_ME_TEMPLATE:
        return <HealthyMeals {...stepProps} />;
      case Q.StepTemplate.BODY_MAKER_LIVE_TEMPLATE:
        return <Bodymaker {...stepProps} />;
      case Q.StepTemplate.BODY_IMPROVER_INFO_TEMPLATE:
        return <Bodyimprover {...stepProps} />;
      case Q.StepTemplate.BETTER_PLAN_CLUB_TEMPLATE:
        return <BetterPlan {...stepProps} />;
      case Q.StepTemplate.BEST_WEIGHT_CLUB_TEMPLATE:
        // @ts-ignore
        return <BestWeight {...stepProps} />;
      case Q.StepTemplate.IMPROVEME_CLUB_TEMPLATE:
        return <ImproveMe {...stepProps} />;
      case Q.StepTemplate.GET_HEALTHY_SPACE_TEMPLATE:
        return <GetHealthy {...stepProps} />;
      case Q.StepTemplate.WEIGHT_DECREASE_COM_TEMPLATE:
        return <WeightDecrease {...stepProps} />;
      case Q.StepTemplate.GO_HUNGRY_INFO_TEMPLATE:
        return <GoHungry {...stepProps} />;
      case Q.StepTemplate.EAT_NOTHING_INFO_TEMPLATE:
        return <EatNothing {...stepProps} />;
      case Q.StepTemplate.BEST_SNACK_COM_TEMPLATE:
        return <BestSnack {...stepProps} />;
      case Q.StepTemplate.HEALTHY_BITES_CLUB_TEMPLATE:
        return <HealthyBites {...stepProps} />;
      case Q.StepTemplate.RIGHT_SHAPE_INFO_TEMPLATE:
        return <RightShape {...stepProps} />;
      case Q.StepTemplate.PERFECT_BODY_INFO_TEMPLATE:
        return <PerfectBody {...stepProps} />;
      case Q.StepTemplate.DIET_MAKER_CLUB_TEMPLATE:
        return <DietMaker {...stepProps} />;
      case Q.StepTemplate.ENERGY_MONSTER_LIVE_TEMPLATE:
        return <EnergyMonster {...stepProps} />;
      case Q.StepTemplate.GAIN_WEIGHT_FUN_TEMPLATE:
        return <GainWeight {...stepProps} />;
      case Q.StepTemplate.DIET_HACKER_INFO_TEMPLATE:
        return <DietHacker {...stepProps} />;
      case Q.StepTemplate.BODY_GUARD_ROCKS_TEMPLATE:
        return <BodyGuard {...stepProps} />;
      case Q.StepTemplate.FIT_KEEPER_INFO_TEMPLATE:
        return <Fitkeeper {...stepProps} />;
      case Q.StepTemplate.TOP_PRIORITY_NOW:
        return <StartOne {...stepProps} />;
      case Q.StepTemplate.HEALTHY_NUTRITION_MADE_SIMPLE:
        return <StartTwo {...stepProps} />;
      case Q.StepTemplate.WEIGHT_LOSS_MADE_EASY_WITH_DISH_PICTURE:
        return <StartThree {...stepProps} />;
      case Q.StepTemplate.WEIGHT_LOSS_MADE_EASY_WITH_MAN_WOMAN_PICTURE:
        return <StartFour {...stepProps} />;
      default:
        return (
          <QuizFormsProvider quizForms={quizForms} stepUrl={stepUrl}>
            <Progress
              onPrev={handleStepChange}
              selectedItemUrl={stepUrl}
              items={navigation.quizSteps}
            />
            <Step {...stepProps} key={router.query.step as string} />
          </QuizFormsProvider>
        );
    }
  };

  return (
    <>
      {getContent()}
      {showEmailConsentModal && (
        <EmailConsentModalSelector
          onSelect={handleEmailConsentSelectorChange}
        />
      )}
    </>
  );
}

export default memo(Quiz);
