export const getFlatFormValue = (
  formValue: Record<string, unknown>
): Record<string, unknown> =>
  Object.keys(formValue).reduce((result, key): Record<string, unknown> => {
    const value: unknown | unknown[] = formValue[key];
    if (Array.isArray(value)) {
      return value.reduce(
        (arrayResult, optionId): Record<string, unknown> => ({
          ...arrayResult,
          [optionId.toString()]: 1,
        }),
        result
      );
    }
    return {
      ...result,
      [key]: formValue[key],
    };
  }, {});

export default getFlatFormValue;
