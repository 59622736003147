import styled, { css } from 'styled-components';

import { H3, Body2 } from 'src/components-2.0/Typography';
import Button from 'src/components-2.0/Button';
import Modal from 'src/components-2.0/Modal';

import { smMedia, themeColor } from 'src/theme/utils';

export const EmailConsentModalInner = styled(Modal)`
  max-width: 500px;
`;

export const EmailConsentModalSelectorWrapper = styled.div`
  padding: 8px 8px 16px;
  text-align: center;

  ${smMedia(
    css`
      padding: 0 0 12px;
    `
  )}
`;

export const EmailConsentModalSelectorImageWrapper = styled.div`
  border-radius: 8px 8px 0 0;
  display: flex;
  justify-content: center;
  margin-left: -24px;
  margin-top: -24px;
  margin-bottom: 24px;
  overflow: hidden;
  width: calc(100% + 48px);

  ${smMedia(
    css`
      margin-left: -40px;
      margin-top: -40px;
      margin-bottom: 24px;
      width: calc(100% + 80px);
    `
  )}
`;

export const EmailConsentModalSelectorImage = styled.div``;

export const EmailConsentModalSelectorTitle = styled(H3)`
  margin-bottom: 8px;
`;

export const EmailConsentModalSelectorCaption = styled(Body2)`
  margin-bottom: 16px;
`;

export const EmailConsentModalSelectorAgreeButton = styled(Button)`
  margin-bottom: 16px;
  width: 100%;
`;

export const EmailConsentModalSelectorDeclineButton = styled(Body2)`
  color: ${themeColor('black40')};
  background: transparent;
  box-shadow: none;
  border: 0;
  padding: 0;
  cursor: pointer;
`;
