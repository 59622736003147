import styled, { css, CSSProp } from 'styled-components';
import ReactModal from 'react-modal';

import { themeColor, smMedia, mdMedia } from 'src/theme/utils';

type ModalWrapperProps = {
  $isOnRequestClose: boolean;
};

export const ModalWrapper = styled(ReactModal)<ModalWrapperProps>`
  padding: 24px;
  overflow: auto;
  opacity: 0;
  transition: opacity 100ms ease-in-out;

  &.ReactModal__Content--after-open {
    opacity: 1;
  }

  &.ReactModal__Content--before-close {
    opacity: 0;
  }

  ${({ $isOnRequestClose }): CSSProp =>
    $isOnRequestClose
      ? css`
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          min-width: 100%;
        `
      : css`
          height: 100%;
          -webkit-overflow-scrolling: touch;
        `}
`;

export const ModalContent = styled.div`
  display: flex;
  width: 100%;
  min-height: 100%;
  justify-content: center;
  align-items: center;
`;

export const ModalInner = styled.div`
  background-color: ${themeColor('black0')};
  box-shadow: 0 8px 24px rgba(46, 46, 46, 0.1);
  border-radius: 12px;
  padding: 16px;
  max-width: 470px;
  width: 100%;
  position: relative;

  ${smMedia(css`
    padding: 40px;
    max-width: 570px;
    width: 100%;
  `)}

  ${mdMedia(css`
    max-width: 470px;
  `)}
`;

export const ModalClose = styled.div`
  position: absolute;
  top: 14px;
  right: 14px;
`;

export const ModalCloseButton = styled.button`
  border: none;
  outline: none;
  padding: 0;
  margin: 0;
  background: inherit;
  cursor: pointer;
  height: 24px;
  width: 24px;

  &:hover {
    opacity: 0.7;
  }
`;
