import { Quiz } from 'src/models/dto';
import { amplitudeLogEvent } from 'src/utils/amplitude';

export const sendQuizFormEvents = (
  formValue: Record<string, unknown>,
  quizData: Quiz.DTO
): void => {
  Object.keys(formValue).forEach((key): void => {
    const value = formValue[key];

    if (formValue[key]) {
      const select = quizData.selects[key];
      if (select) {
        (value as number[]).forEach((id) => {
          const option =
            select.reset && select.reset.id === id
              ? select.reset
              : select.options.find((item) => item.id === id);
          amplitudeLogEvent(option?.analyticId || '');
        });
      } else {
        Object.keys(quizData.inputs).forEach((inputKey): void => {
          quizData.inputs[inputKey].controls.forEach((control) => {
            if (control.id.toString() === key) {
              amplitudeLogEvent(control.analyticId);
            }
          });
        });
      }
    }
  });
};

export default sendQuizFormEvents;
