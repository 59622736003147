import { ElementType, ButtonHTMLAttributes, memo } from 'react';

import { ButtonWrapper } from './button-styled';

export type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  size: 'big' | 'medium' | 'small';
  kind: 'primary' | 'secondary' | 'text';
  as?: ElementType;
};

function Button({ size, kind, children, ...props }: ButtonProps): JSX.Element {
  return (
    <ButtonWrapper $size={size} $kind={kind} {...props}>
      {children}
    </ButtonWrapper>
  );
}

export default memo(Button);
