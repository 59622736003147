import { memo, createContext } from 'react';

import { QuizForms } from 'src/containers/Quiz/models';

interface QuizFormsProviderProps {
  quizForms: QuizForms;
  stepUrl: string;
}

export const QuizFormsContext = createContext<QuizFormsProviderProps>({
  quizForms: {},
  stepUrl: '',
});

type Props = React.PropsWithChildren<QuizFormsProviderProps>;

// eslint-disable-next-line react/display-name
export const QuizFormsProvider = memo<Props>(
  ({ quizForms, stepUrl, children }: Props): JSX.Element => (
    <QuizFormsContext.Provider value={{ quizForms, stepUrl }}>
      {children}
    </QuizFormsContext.Provider>
  )
);
