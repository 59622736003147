export namespace FinalA {
  export interface WeightPredictionCalendar {
    month: string;
    value: number;
  }

  export interface WeightPrediction {
    header: string;
    description: string[];
    value: string;
    by: string;
    calendar: WeightPredictionCalendar[];
    weightDiff: {
      value: number;
    };
  }

  export interface AfterFirstWeek {
    header: string;
    image: string;
    value: string;
  }

  export interface DailyWaterMinimum {
    header: string;
    image: string;
    value: string;
  }

  export interface DailyCalorieNorm {
    header: string;
    image: string;
    value: string;
  }

  export interface Duration {
    header: string;
    image: string;
    value: string;
  }

  export interface YourBodyDimensionsList {
    image: string;
    header: string;
    value: string;
  }

  export interface YourBodyDimensions {
    header: string;
    list: YourBodyDimensionsList[];
    weight: {
      value: number;
      label: string;
    };
    height: {
      value: number;
      label: string;
    }[];
  }

  export interface Bmi {
    header: string;
    value: string;
    label: string;
  }

  export interface YesYouCan {
    image: string;
    header: string;
    description: string[];
    value: string;
    label: string;
  }

  export interface ReduceProblemAreasList {
    title: string;
    image: string;
  }

  export interface ReduceProblemAreas {
    header: string;
    image: string;
    list: ReduceProblemAreasList[];
    description: string[];
  }

  export interface WhatYouGetList {
    header: string;
    title: string;
    description: string[];
    image: string;
  }

  export interface WhatYouGet {
    list: WhatYouGetList[];
  }

  export interface BenefitsList {
    image: string;
    header: string;
    description: string[];
  }

  export interface Benefits {
    header: string;
    list: BenefitsList[];
  }

  export interface Discount {
    header: string;
    timer: number;
  }

  export interface StartLooseWeight {
    header: string;
    title: string;
    image: string;
  }

  export interface Option {
    name: string;
  }

  export interface Price {
    originAmount: number;
    period?: string;
    pricePeriod?: number;
    sumInCoins: number;
    type: string;
    userAmount: number;
    userCurrency: string;
  }

  export interface WorkoutUpsell {
    id: number;
    image: string;
    link: string;
    name: string;
    options: Option[];
    prices: Price[];
    title: string;
  }
  export interface PaymentBlock {
    image: string;
    mobileImage: string;
    workoutUpsell: WorkoutUpsell;
  }

  export interface AchievedGreatResults {
    header: string;
    description: string[];
    image: string;
  }

  export interface PeopleOftenAskList {
    header: string;
    description: string[];
    image: string;
  }

  export interface PeopleOftenAsk {
    header: string;
    list: PeopleOftenAskList[];
  }

  export interface FeedbacksList {
    image: string;
    description: string[];
    name?: string;
    stars?: string[];
  }

  export interface Feedbacks {
    header: string;
    list: FeedbacksList[];
  }

  export interface MoneyBackGuarantee {
    icon: string;
    header: string;
    description: string[];
  }

  export interface SecuredByList {
    image: string;
  }

  export interface SecuredBy {
    list: SecuredByList[];
  }

  export interface PaymentBlocks {
    discount: Discount;
    startLooseWeight: StartLooseWeight;
    paymentBlock: PaymentBlock;
    achievedGreatResults: AchievedGreatResults;
    peopleOftenAsk: PeopleOftenAsk;
    feedbacks: Feedbacks;
    moneyBackGuarantee: MoneyBackGuarantee;
    securedBy: SecuredBy;
  }

  export enum FINAL_TEMPLATE {
    DEFAULT = 'DEFAULT',
    NEW_PROGNOSIS = 'NEW_PROGNOSIS',
    WITH_BUTTON_OPEN_MY_PLAN = 'WITH_BUTTON_OPEN_MY_PLAN',
    FINAL_PAGE_WITH_WITHOUT_WEIGHTS = 'FINAL_PAGE_WITH_WITHOUT_WEIGHTS',
    UPD_FINAL = 'UPD-FINAL',
    RESULT_GRAPH_FINAL = 'RESULT_GRAPH_FINAL',
    INTRO_FINAL = 'INTRO_FINAL',
    FINAL_AD = 'FINAL_AD',
    INTROFINAL_WYG_REVIEWS = 'INTROFINAL_WYG_REVIEWS',
  }

  export enum FINAL_TEMPLATE_URL_NAMES {
    rslt = FINAL_TEMPLATE.RESULT_GRAPH_FINAL,
    intr = FINAL_TEMPLATE.INTRO_FINAL,
    ad = FINAL_TEMPLATE.FINAL_AD,
  }

  export interface DTO {
    finalTemplate: string;
    pricePackageId: number;
    priceTemplate: string;
    weightPrediction: WeightPrediction;
    afterFirstWeek: AfterFirstWeek;
    dailyWaterMinimum: DailyWaterMinimum;
    dailyCalorieNorm: DailyCalorieNorm;
    duration: Duration;
    yourBodyDimensions: YourBodyDimensions;
    bmi: Bmi;
    yesYouCan: YesYouCan;
    reduceProblemAreas: ReduceProblemAreas;
    whatYouGet: WhatYouGet;
    benefits: Benefits;
    paymentBlocks: PaymentBlocks;
  }
}
